import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'

configure({ enforceActions: 'never' })

export type lang = 'eng' | 'ru'

export interface IDistTranslation {
	ru: string
	eng: string
}
class Lang {
	// выбрынный язык сейчас
	current: lang = 'ru'
	/*
    Замкнутая на словарь функция,
    принимает в себя словарь и возращает
    функцию которая возвращает строку
    с текущим языом
  */
	translateWithDictionary =
		(dict: IDistTranslation[]) =>
		(str: string): string => {
			const lang = this.current
			const obj = dict.find(e => e.ru === str)
			if (obj) {
				return obj[lang]
			}
			return str
		}

	// Функция смены языка
	changeLanguage() {
		if (Language.current === 'ru') {
			Language.current = 'eng'
			localStorage.setItem('language', Language.current)
		} else {
			Language.current = 'ru'
			localStorage.setItem('language', Language.current)
		}
	}

	handleLanguage() {
		const defaultValue = this.current
		try {
			const ReduceValue = localStorage.getItem('language')
			if (!ReduceValue) {
				localStorage.setItem('language', `${defaultValue}`)
			} else {
				if (ReduceValue === 'ru' || ReduceValue === 'eng') {
					this.current = ReduceValue
				}
			}
		} catch (e) {
			console.log(e)
			console.log('%c Error on localstorage', 'color: red')
		}
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export const Language = new Lang()
