export const REGISTRATION_ROUTE = '/registration'
export const PROFILE_ROUTE = '/profile'

// страница авторизации в систему
export const LOGIN_ROUTE = '/login'
// страница категорий $fixme
export const CURRENT_CATEGORY_ROUTE = '/category/'
// страница генирации QR кода после создания заказа
// страница администратора
export const ADMINISTRATOR_ROUTE = '/admin/*'
//(в разработке) страница интеграции 1С  $fixme
export const ITEGRATION1C_ROUTE = '/1cintegration'
//(в разработке) страница тестирования  $fixme
export const TESTING_ROUTE = '/testing'
// (в разработке) страница интеграции битрикс24 $fixme
export const BITRIX24_ROUTE = '/bitrix24'
// вебсокет страница с списком продуктов
export const WSCOLLECT_ROUTE = '/collect'
// вебсокет страница-стеллаж с продуктами
export const WSCLIENT_HORIZONTAL_ROUTE = '/horizontal-wsclient/'
// выбор метода сборки по инструкции
// главная страница
export const MAIN_ROUTE = '/'
