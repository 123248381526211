import { $authHost, $host } from '.'
import User from '../store/user'
import { IUser } from '../types/user'
import { jwtDecode } from 'jwt-decode'

interface IAuthorizationProps {
	username: string
	password: string
}

export const Authorization = async ({
	username,
	password
}: IAuthorizationProps) => {
	try {
		if (User.fetchStatus === 'fetch') return
		User.fetchStatus = 'fetch'
		const { data } = await $host.post('api/login/', { username, password })
		localStorage.setItem('access_token', data.access)
		localStorage.setItem('refresh_token', data.refresh)
		//@ts-ignore
		User.role = jwtDecode(data.access).role
		User.isAuth = true
		User.Authorization = true
		User.fetchStatus = 'success'
		setTimeout(() => {
			User.fetchStatus = 'none'
		}, 500)
		if (User.rememberMe) {
			const user = await User.getCurrentUser()
			User.addLogUsersList(user)
		}
	} catch (e) {
		User.fetchStatus = 'error'
		setTimeout(() => {
			User.fetchStatus = 'none'
		}, 500)
		User.isAuth = false
		User.Authorization = true
	}
}

export const updateToken = async (refresh: string | undefined | null) => {
	try {
		if (User.loginStatus === 'fetch') return
		User.loginStatus = 'fetch'
		if (!refresh) return
		const res = await $host.post('api/login/refresh/', { refresh })
		localStorage.setItem('access_token', res.data.access)
		localStorage.setItem('refresh_token', refresh)
		//@ts-ignore
		const { user_id, role } = jwtDecode(res.data.access)
		const { data } = await $authHost.get<IUser>(`api/user/${user_id}`)
		User.loginStatus = 'success'
		User.role = role
		console.log(User.role)
		User.UserData = data
		setTimeout(() => {
			User.loginStatus = 'none'
		}, 500)
		//@ts-ignore
		User.isAuth = true
		User.Authorization = true
	} catch (e: any) {
		if (e?.response?.status === 401 || e?.response?.status === 400) {
			localStorage.removeItem('refresh_token')
			localStorage.removeItem('access_token')
			window.location.href = '/login'
		}
	}
}
