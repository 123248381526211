import { publicRoutes } from '../Routes'
import { useMount } from '../hooks/useMount'
import user from '../store/user'
import { IRoutes } from '../types/routes'
import { Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { FC, Suspense, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

const AppRouter: FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		if (!user.Authorization) {
			navigate('/login')
		}
	}, [user.Authorization, window.location.pathname])

	useMount(() => {
		if (!localStorage.access_token && !localStorage.refresh_token) {
			navigate('/login')
		}
	})

	return (
		<Routes>
			{publicRoutes.map(({ path, Сomponent }: IRoutes): React.ReactNode => {
				return (
					<Route
						key={path}
						path={path}
						element={
							<Suspense fallback={<Spin fullscreen></Spin>}>
								<Сomponent />
							</Suspense>
						}
					/>
				)
			})}
		</Routes>
	)
}
export default observer(AppRouter)
