import './App.css'
import AppRouter from './components/AppRouter'
import { useMount } from './hooks/useMount'
import Company from './store/Company/Company'
import SettingsCollectSystem from './store/Settings/SettingsCollectSystem/SettingsCollectSystem'
import Theme from './store/Theme'
import User from './store/user'
import { Language } from './utils/langSwitch'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider, ThemeConfig } from 'antd'
import RussianLanguage from 'antd/locale/ru_RU'
import { BrowserRouter } from 'react-router-dom'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: true
		}
	}
})

const theme: ThemeConfig = {}

const App = () => {
	useMount(() => {
		// Handlers
		Theme.handleThemeChange()
		Language.handleLanguage()
		Company.handleCompany()
		User.handleLogin()
		SettingsCollectSystem.HandleReduceCollect()
	})
	return (
		<QueryClientProvider client={queryClient}>
			<ConfigProvider
				slider={{
					className: 'bg-gray-200'
				}}
				theme={theme}
				locale={RussianLanguage}
			>
				<BrowserRouter>
					<AppRouter />
				</BrowserRouter>
			</ConfigProvider>
		</QueryClientProvider>
	)
}
export default App
