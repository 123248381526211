import { noPhoto } from './nophoto'

export interface ImageProps {
	PhotoBytea: string | null
	ImageType?: null | string
}

export const reformImage = (props: ImageProps): string => {
	if (!props.PhotoBytea) {
		return `data:image/png;base64, ${noPhoto}`
	}
	if (props?.ImageType) {
		return `data:${props.ImageType};base64, ${props.PhotoBytea}`
	} else {
		return `data:image/png;base64, ${props.PhotoBytea}`
	}
}
